import Head from 'next/head'
import { Fragment } from 'react'

import GlobalStyles from 'components/layouts/global-styles'

const DefaultLayout = ({ children }) => (
  <Fragment>
    <GlobalStyles />
    <Head>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Oswald:300,400,700|Roboto+Condensed:300,400,700|Lato:300,400,700"
      />
      {/*
      <link
        rel="icon"
        type="image/png"
        href={require('/public/img/favicon-32x32.png')}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={require('/public/img/favicon-16x16.png')}
        sizes="16x16"
      />
      */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>
    {children}
  </Fragment>
)

export default DefaultLayout
