import reset from 'emotion-reset'
import { css, Global } from '@emotion/core'

const globalStyles = () => (
  <Global
    styles={css`
      ${reset} * {
        font-family: 'Roboto Condensed', sans-serif;
      }
    `}
  />
)

export default globalStyles
